import './App.css';
import govgr from './govgr_logo.svg';
import greek_gov from './elliniki_kivernisi_white.svg';

function App() {
  return (
    <div className="App">
      <div class="container">
        <main>
          <img src={govgr} class="logo" alt="GOV.GR | Maintenance is in progress, please hang tight" />
          <h1>Maintenance is in progress, please hang tight</h1>
        </main>
        <footer>
          <img src={greek_gov} alt="Ελληνική Κυβέρνηση" class="gov" />
        </footer>
      </div>
    </div>
  );
}

export default App;
